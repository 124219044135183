* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: white;
  color: black;
  max-width: 100% !important;
  margin-top: 0 !important;
  overflow-x: hidden;
}

.products {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.blog {
  width: 100%;
  background-color: black;
  opacity: 0.3;
  position: absolute;
  left: 0;
  transition: 0.3s;
}

.blog:hover {
  opacity: 0.4;
}

.products li a {
  display: inline-block;
  padding: 24px;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease-in;
}

.product {
  padding: 10px;
  z-index: 1;
}

.product h1 {
  font-size: 40px;
  margin-left: 0;
}
.product {
  font-size: 20px;
  line-height: 2em;
}
.product img {
  width: 100%;
}

hr {
  margin: 24px 0;
  height: 2px;
  background-color: #222;
  border: none;
}

a {
  color: black;
  font-size: 20px;
  margin-top: 20px;
}
